import React, { Fragment, useEffect, useState } from "react";
import Toast from "../components/Toast";
import RendererEngine from "./RendererEngine";
import RendererEngineNew from "./RendererEngineNew";
import {
  PracticeFrontNode,
  SEOItems,
} from "../typings/interfaces-v1/practice-front-node.interface";
import { useQuery } from "react-query";
import PageLoader from "../components/PageLoader";
import PageError from "../components/PageError";
import ThemeStore from "../stores/theme-store";
import useThemeStore from "../stores/theme-store";
import { getPracticeFrontByDomain } from "../apiManager/practiceFrontsApis/practiceFront";
import Head from "../components/Head";
import { useLocation, useParams } from "react-router-dom";
import Scroll from "react-scroll";
import PixelTracking from "../components/PixelTracking";
import { practiceFrontQueryKeys } from "../apiManager/query-keys";

function getSubdomain(domain: any) {
    if (domain.includes('mydocspace.com')) {
        // @ts-ignore
        const host = /:\/\/([^/]+)/.exec(domain)[1]
        return host.split('.')[0]
    } else {
        let result:any;
        if (domain.indexOf("http://") === 0 || domain.indexOf("https://") === 0) {
            const url = (new URL(domain));
            result = url.hostname
        } else {
            result = domain
        }
        return result.replace('www.', '') || domain;
    }
}

// function getSubdomain(domain:any){
//     // @ts-ignore
//    const host= /:\/\/([^/]+)/.exec(domain)[1]
//     return  host.split('.')[0]
// }

const defaultSeo: SEOItems = {
  siteDescription: "Ease site",
  siteFavIcon: "/public/corgi.png",
  siteKeywords: "Ease,website",
  siteTitle: "Ease",
};


const AppContainer: React.FC = () => {
  let location = useLocation();
  let { page } = useParams();

  const subdomain = getSubdomain(window.location.href);
  const scroller = Scroll.scroller;
  const [seoSettings, setSeoSettings] = useState<SEOItems>(defaultSeo);
  const { setTheme } = ThemeStore((state) => ({
    setTheme: state.setTheme,
  }));
  const { pixelSettings, setPixelSettings } = useThemeStore();

  const {
    isLoading: practiceTreeLoading,
    error,
    data: practiceFrontTree,
  } = useQuery(
    practiceFrontQueryKeys.practiceFrontByDomainAndPage(subdomain, page),
    () => getPracticeFrontByDomain({ subdomain: subdomain, page }),
    {
      enabled: !!subdomain && !!location,
      retry: 1,
      onSuccess: (res) => {
        if (res?.response?.children) {
          setTheme(prepareNodes(res.response) as any);
        }
      },
    }
  );

  useEffect(() => {
    window.addEventListener("beforeunload", function (e) {
      localStorage.removeItem("path");
    });
  }, []);

  //   useEffect(() => {
  //     if (subdomain && location) {
  //       if (
  //         window &&
  //         (!localStorage.getItem("path") ||
  //           localStorage.getItem("path") !== location?.pathname)
  //       ) {
  //         mutatePracticeFrontByDomain(
  //           {
  //             subdomain,
  //             page,
  //           },
  //           {
  //             onSuccess: (res) => {
  //               if (res?.response?.children) {
  //                 setTheme(prepareNodes(res.response) as any);
  //                 localStorage.setItem("path", location.pathname);
  //               }
  //             },
  //             onError: () => {
  //               // setSubdomainStage(true)
  //             },
  //           }
  //         );
  //       }
  //     }
  //   }, [page, location, subdomain]);

  const IntervalRef = setInterval(() => {
    if (location.hash && practiceFrontTree && !practiceTreeLoading) {
      scroller.scrollTo(location.hash.slice(1).toLocaleLowerCase(), {
        duration: 500,
        delay: 10,
        smooth: "easeInOutQuint",
      });
      clearInterval(IntervalRef);
    }
  }, 1000);

  useEffect(() => {
    if (practiceFrontTree?.response?.children) {
      // setTheme(prepareNodes(practiceFrontTree.response) as any)
      const siteSeoData = {
        siteDescription:
          practiceFrontTree?.response?.practiceFrontData?.seoSettings
            ?.siteDescription || "Website",
        siteFavIcon:
          practiceFrontTree?.response?.practiceFrontData?.seoSettings
            ?.siteFavIcon || "",
        siteKeywords:
          practiceFrontTree?.response?.practiceFrontData?.seoSettings
            ?.siteKeywords || "Ease ,website",
        siteTitle:
          practiceFrontTree?.response?.practiceFrontData?.seoSettings
            ?.siteTitle || "Landing",
      };
      setSeoSettings(siteSeoData);
      setPixelSettings(
        practiceFrontTree?.response?.practiceFrontData?.pixelSettings || null
      );
    }
  }, [setTheme, practiceFrontTree, setPixelSettings]);

  const handleHomeRoute = () => {
    window.location.href = "https://easepractice.com/";
  };

  if (practiceFrontTree && !practiceFrontTree.response) {
    return (
      <PageError
        title="Oh no!"
        subTitle={"Sorry we could not find any website for this "}
        ctaButtonText={"Visit easepractice "}
        onCTAClick={handleHomeRoute}
      />
    );
  }

  if (error) {
    return (
      <PageError
        title="Oh no!"
        subTitle={"Sorry we could not find any website for this "}
        ctaButtonText={"Visit Ease practice "}
        onCTAClick={handleHomeRoute}
      />
    );
  }

  if (practiceFrontTree && practiceFrontTree.response) {
    return (
      <Fragment>
        {pixelSettings && <PixelTracking />}
        <Head
          pixelSettings={pixelSettings}
          seoSettings={seoSettings}
          subdomain={subdomain as string}
        />
        {practiceFrontTree?.response?.version === "v2" ? (
          <RendererEngineNew />
        ) : (
          <RendererEngine />
        )}
      </Fragment>
    );
  }
  return <PageLoader />;
};

const prepareNodes = (tree: PracticeFrontNode) => {
  tree.children?.sort(
    (a: any, b: any) => a.generalSettings.order - b.generalSettings.order
  );
  tree.path = tree.path || [];
  delete (tree as any).__v;

  if (tree.children?.length) {
    tree.children.forEach((node, index) => {
      node.path = [...(tree.path || [])];
      node.path.push(index);
      prepareNodes(node);
    });
  }
  return tree;
};

export default AppContainer;
